body {
  background-color: #fafafa;
  letter-spacing: -0.3px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.6;
  font-weight: 400;
  font-family: open sans, Helvetica, Arial, sans-serif;
  margin: 0;
}

.ant-input {
  min-height: 40px;
  padding: 0px 10px;
}

.ant-input-password {
  padding: 0px 10px;
}

.ant-btn {
  min-height: 40px;
}

.otp{
  min-height: 28px;
  min-width: 28px;
}

.ant-menu-item-selected {
  color: #051721 !important;
  background-color: #3ECF8E !important;
}

.ant-pro-query-filter-row-split{
  .ant-form-item-row { 
    align-items: center;

    .ant-input{
      min-height: 20px;
    }
  }
}

.ant-input{
  min-height: 20px;
}

.ant-form-item-label {
  padding: 0 !important;
}

.ant-pro-table-list-toolbar-container {
  padding: 0 10px;
}

.sidebar {
  display: none;
}

.sidebar-mobile-close {
  position: absolute;
  left: -200px;
  z-index: 10;
  transition: 200ms;
}

.sidebar-mobile-open {
  left: 0;
  transition: 200ms;
}
/* 
.ant-input {
  min-height: 40px;
} */
